import { useState, useEffect } from 'react';

const useHeight = (nodeId) => {
    const [height, setHeight] = useState(0);

    useEffect(() => {
        const updateHeight = () => {
            const element = document.getElementById(nodeId);
            const computedStyle = window.getComputedStyle(element);
            const h = computedStyle.getPropertyValue('height');
            const marginBottom = Number(h.substring(0, h.length - 2));
            setHeight(marginBottom);
        };

        // Update the height initially
        updateHeight();

        // Add event listener for window resize
        window.addEventListener('resize', updateHeight);

        // Cleanup the event listener on unmount
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [nodeId]);

    return height;
};

export default useHeight;