import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FetchWrapper from "../../util/fetchWrapper.js";
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Footer from "../../components/Footer/footer";
import useHeight from "../../util/useHeight.js";
import "./index.css";

const BetaTestNotFound = ({ betaTestName }) => {
    return (
        <>
            <div className="beta-test-section col-lg-7 col-md-12 col-sm-12 pt-2 mt-4 text-center bg-danger">
                <h1>Beta Test: {betaTestName} was not found!</h1>
            </div>
        </>
    )
}

const BetaTestContents = ({ pageData, betaTestName, fw }) => {
    const [isAccessRequested, setIsAccessRequested] = useState(false);
    const [hasSuccessfullyRequestedAccess, setHasSuccessfullyRequestedAccess] = useState(false);
    const validationSchema = Yup.object({
        // email: Yup.string().email('Invalid email').required('Required'),
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
    });

    const handleSubmit = (values) => {
        console.log(values);
        fw.post('/testers/request-tester-access', {
            betaTestName,
            email: values.email
        }).then(data => {
            console.log(data);
            if (data.errorMessage) {
                alert(data.errorMessage);
                return;
            }
            setHasSuccessfullyRequestedAccess(true);
        });
    };
    return (
        <>
            <div className="beta-test-section col-lg-7 col-md-12 col-sm-12 pt-2 mt-4 text-center">
                <h1>Beta Test: {pageData?.betaTest?.title}</h1>
            </div>

            {pageData?.betaTest?.privacyStatusId !== 3 && (<div className="beta-test-section col-lg-7 col-md-12 col-sm-12 pt-2 mt-4 font-size-20">
                {pageData?.betaTest?.description?.split('\n').map((item, key) => {
                    return <React.Fragment key={key}>{item}<br /></React.Fragment>
                })}

                <br />

                {pageData?.betaTest?.incentives?.split('\n').map((item, key) => {
                    return <React.Fragment key={key}>{item}<br /></React.Fragment>
                })}
            </div>)}
            {pageData?.betaTest?.privacyStatusId === 1 && (
                <div className="beta-test-section-unstyled col-lg-1 col-md-6 col-sm-12 pt-2 mt-4 text-center text-nowrap center-buttons">
                    <div className="button-wrapper">
                        <div className="label-wrapper left">
                            <label htmlFor="join-beta-test">Already have an account?</label>
                            <a href={pageData?.joinLink} target="_blank" rel="noopener noreferrer">
                                <button
                                    id="join-beta-test"
                                    name="join-beta-test"
                                    className="btn green-bg"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(pageData?.joinLink, '_blank');
                                    }}
                                >
                                    Join the Beta Test
                                </button>
                            </a>
                        </div>

                        <div className="label-wrapper right">
                            <label htmlFor="register-beta-test">Don't have an account yet?</label>
                            <a href={pageData?.joinLinkRegistration} target="_blank" rel="noopener noreferrer">
                                <button
                                    id="register-beta-test"
                                    name="register-beta-test"
                                    className="btn blue-bg"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(pageData?.joinLinkRegistration, '_blank');
                                    }}
                                >
                                    Register and Join
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            )}

            {pageData?.betaTest?.privacyStatusId === 2 && (
                <div className="beta-test-section-unstyled col-lg-1 col-md-6 col-sm-12 pt-2 mt-4 text-center text-nowrap center-buttons">
                    <div className="button-wrapper">
                        <div className="label-wrapper">
                            <label htmlFor="join-beta-test">Access to this test is by request only</label>

                            <Formik
                                initialValues={{
                                    email: pageData?.email ?? ''
                                }}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                <Form className="col-12">
                                    {hasSuccessfullyRequestedAccess && (
                                        <div className="bg-warning text-white col-12 text-wrap">
                                            Your request has been submitted. You will receive an email when your request has been approved.
                                        </div>)}
                                    {!hasSuccessfullyRequestedAccess && (<>
                                        <Field type="email" name="email" placeholder="Enter your email" className="form-control col-5 mb-0" />
                                        <ErrorMessage name="email" component="div" className="error" />

                                        <button
                                            id="join-beta-test"
                                            name="join-beta-test"
                                            className="btn green-bg mt-3"
                                            type="submit"
                                        >
                                            Request Access
                                        </button>
                                    </>)}
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
            )}

            {pageData?.betaTest?.privacyStatusId === 3 && (
                <div className="beta-test-section-rounded col-lg-4 col-md-6 col-sm-12 pt-2 mt-4 text-center center-buttons bg-danger">
                    Unfortunately this beta test is private and is not accepting requests to join. <br />Please check back later.
                </div>)}
        </>
    );
}

const BetaTest = () => {
    const fw = FetchWrapper();
    const [pageData, setPageData] = useState({});
    const [failedToFindBetaTest, setFailedToFindBetaTest] = useState(false);
    const fillerHeight = useHeight("navbar-body")

    let params = useParams();
    const { betaTestName } = params;
    const navItems = [
        { id: 1, idnm: "home", navheading: "Home" },
        { id: 2, idnm: "services", navheading: "Services" },
        { id: 7, idnm: "contact", navheading: "Contact" }

    ];

    useEffect(() => {
        fw.get('/betatests/get-beta-test-description?betaTestName=' + betaTestName)
            .then(data => {
                if (data?.failedToFindBetaTest) {
                    setFailedToFindBetaTest(true);
                    return;
                }

                setPageData(data);
            })
    }, [betaTestName])

    return (
        <React.Fragment>
            <div className="beta-test-container">
                <NavbarPage navItems={navItems} navClass="is-sticky" disableScrollSticky={true} />
                <div style={{ minHeight: fillerHeight, height: fillerHeight }} />
                <div style={{ flex: '1' }}>
                    {
                        failedToFindBetaTest ?
                            <BetaTestNotFound betaTestName={betaTestName} /> :
                            <BetaTestContents pageData={pageData} betaTestName={betaTestName} fw={fw} />
                    }
                </div>
                <Footer />
            </div>

        </React.Fragment>
    )
};

export default BetaTest;