import React, { useEffect } from 'react';
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Footer from "../../components/Footer/footer";
import useHeight from "../../util/useHeight.js";
import './PrivacyPolicy.css'
const PrivacyPolicy = () => {
    const fillerHeight = useHeight("navbar-body")
    const navItems = [
        { id: 1, idnm: "home", navheading: "Home" },
        { id: 2, idnm: "services", navheading: "Services" },
        // { id: 3, idnm: "features", navheading: "Features" },
        // { id: 4, idnm: "pricing", navheading: "Pricing" },
        // { id: 5, idnm: "team", navheading: "Team" },
        // { id: 6, idnm: "blog", navheading: "Blog" },
        { id: 7, idnm: "contact", navheading: "Contact" }

    ];
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <React.Fragment>
            <div className="beta-test-container">
                <NavbarPage navItems={navItems} navClass="is-sticky" disableScrollSticky={true} />
                <div style={{ minHeight: fillerHeight, height: fillerHeight }} />
                <div style={{ flex: '1' }}>


                    <div className='privacy-policy'>
                        <h1>Privacy Policy for BetaTestMyProduct</h1>
                        <h4>Introduction</h4>
                        <p>Welcome to BetaTestMyProduct. This Privacy Policy outlines the types of personal information we collect and how we use, disclose and protect that information.</p>

                        <h4>Information We Collect</h4>
                        <p>When you use our site, we collect the following types of information:</p>
                        <ul>
                            <li>First and last name</li>
                            <li>Email address</li>
                        </ul>
                        <p>Your information is collected when you sign up for an account via OAuth sign-ins (Google, GitHub, etc.) or when you enter this information into forms on our site.</p>

                        <h4>Use of Information</h4>
                        <p>We use the information collected primarily for authentication and identification within our site. It allows us to provide you with access to different aspects of our site.</p>

                        <h4>Data Storage and Protection</h4>
                        <p>We store your personal information in a secure database. We use a variety of security measures to ensure the safety of your personal information.</p>

                        <h4>Third-Party Sharing</h4>
                        <p>We currently do not share your personal information with any third parties.</p>

                        <h4>Opt-Out & Modification</h4>
                        <p>As we only collect necessary information for authentication and identification, users cannot opt out of providing this information if they want to use our services. However, users can modify their personal information through the website.</p>

                        <h4>Compliance With Laws</h4>
                        <p>We strive to ensure that we handle your personal information in accordance with applicable laws and regulations, including those related to data privacy.</p>

                        <h4>Contact Us</h4>
                        <p>If you have any questions about this Privacy Policy, please feel free to contact us through our website or at the email <a href="mailto:support@betatestmyproduct.com">support@betatestmyproduct.com</a></p>

                        <h4>Changes To This Privacy Policy</h4>
                        <p>We reserve the right to modify this Privacy Policy at any time. Please review it occasionally. If we make changes to this Privacy Policy, the updated Privacy Policy will be posted on the Site in a timely manner, and, if we make material changes, we will provide a prominent notice.</p>
                    </div>
                </div>
                <Footer />
            </div>

        </React.Fragment >
    )
};

export default PrivacyPolicy;
