import React from "react";
import { Route, Routes } from "react-router-dom";
import BetaTest from "./pages/BetaTest/index.js"
import Index2 from "./pages/Index2/Index2";
import PrivacyPolicy from "./components/Privacy/PirvacyPolicy.js";
import "./index.css"

function App() {
  document.title = 'Beta Test My Product'
  return (
    <Routes>
      <Route path="/" element={<Index2 />} />
      <Route path="/:betaTestName" element={<BetaTest />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      {/* <Route path="/:betaTestName" render={(props) => { <BetaTest props={props} /> }} /> */}
    </Routes>
  );
}

export default App;
