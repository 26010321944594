import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";

class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services1: [
        {
          icon: "pe-7s-attention",
          title: "Issue Tracking",
          desc:
            // "Easily collect bug reports from your testers",
            // "the best platform to manage your beta testers, you can easily communicate with them and collect their bug reports. What's more, you can track the whole testing process at the dashboard.",
            // "Helps you identify problems sooner rather than later"
            "Easily collect bug reports and identify problems sooner rather than later"
        },
        {
          icon: "pe-7s-plugin",
          title: "Feature Requests",
          desc:
            "Allow your customers to tell you exactly what they want from your product.",
        },
        {
          icon: "pe-7s-photo",
          title: "File Uploads",
          desc:
            "Testers can upload photos or videos to better communicate information",
        },
      ],
      services2: [
        {
          icon: "pe-7s-cloud-upload",
          title: "Migrate Tickets",
          desc:
            "Seamlessly migrate tickets entered by your testers to your preferred project management suite",
        },
        {
          icon: "pe-7s-tools",
          title: "Maker Tools",
          desc:
            "SDK libraries to use our api and make collecting feedback even easier",
        },
        {
          icon: "pe-7s-alarm",
          title: "Get Notified",
          desc:
            "Get a notification everytime something important happens with your beta test",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className={"section " + this.props.sectionClass} id="services">
          <Container>
            {/* section title */}
            <SectionTitle
              title="What we do"
              desc="Discover a smarter way to beta test with our platform. Here, testers can report bugs and suggest features, directly influencing product development. Seamlessly integrated with JIRA, Trello, and Asana, you can effortlessly export valuable insights into your backlog software. It's more than a bug reporting tool - it's a streamlined bridge from user feedback to development action."
            />

            <Row className="mt-4">
              {/* services box */}
              <ServiceBox services={this.state.services1} />
            </Row>

            <Row className="mt-4">
              {/* service box */}
              <ServiceBox services={this.state.services2} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Process;
