import React, { Component } from "react";
import {
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
  Button,
} from "reactstrap";

import { Link } from "react-router-dom";

import ScrollspyNav from "./scrollSpy";

class NavbarPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
      navClass: props?.navClass ?? "",
      disableScrollSticky: props?.disableScrollSticky ?? false,
    };
  }

  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  componentDidMount() {
    if (this.state.disableScrollSticky === false) {
      window.addEventListener("scroll", this.scrollNavigation, true);
    }
  }

  componentWillUnmount() {
    if (this.state.disableScrollSticky === true) {
      window.removeEventListener("scroll", this.scrollNavigation, true);
    }
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    console.log("scrollup", scrollup)
    if (scrollup > 50) {
      this.setState({ navClass: "is-sticky" });
    } else {
      this.setState({ navClass: "" });
    }
  }

  render() {
    //Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
    let targetId = this.props.navItems.map((item) => {
      return item.idnm;
    });
    return (
      <React.Fragment>
        <div id="navbar" className={this.state.navClass}>
          <nav
            id="navbar-body"
            className="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark"
          >
            <Container>
              <span onClick={(e) => {
                e.preventDefault();
                window.location.href = "/";
              }}>
                <NavbarBrand className="logo text-uppercase" href="/">
                  <img
                    alt="team"
                    src="assets/images/btmp-logo-image-white.png"
                    className="img-fluid"
                    height={35}
                    width={35}
                  />
                  <span
                    style={{ fontFamily: "DejaVu Sans", fontSize: ".75rem", color: "white" }}
                  >
                    BetaTestMyProduct
                  </span>
                </NavbarBrand>
              </span >
              <NavbarToggler onClick={this.toggle}>
                <i className="mdi mdi-menu"></i>
              </NavbarToggler>

              <Collapse
                id="navbarCollapse"
                isOpen={this.state.isOpenMenu}
                navbar
              >
                <ScrollspyNav
                  scrollTargetIds={targetId}
                  scrollDuration="800"
                  headerBackground="true"
                  activeNavClass="active"
                  className="navbar-collapse"
                >
                  <Nav navbar className="navbar-center" id="mySidenav">
                    {this.props.navItems.map((item, key) => (
                      <NavItem
                        key={key}
                        className={item.navheading === "Home" ? "active" : ""}
                      >
                        <NavLink href={"#" + item.idnm}>
                          {" "}
                          {item.navheading}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <div className="nav-button ms-auto">
                    <Nav navbar className="navbar-end">
                      <li>
                        <Button
                          color="none"
                          type="button"
                          className=" btn-primary navbar-btn btn-rounded waves-effect waves-light"
                          onClick={() => {
                            window.location = "https://app.betatestmyproduct.com/user/login?returnUrl=%2Fdashboard"
                          }}
                        >
                          Login
                        </Button>
                      </li>
                      <li>
                        <Button
                          color="none"
                          type="button"
                          className="btn btn-primary navbar-btn btn-rounded waves-effect waves-light"
                          onClick={() => {
                            window.location = "https://app.betatestmyproduct.com/user/register"
                          }}
                        >
                          Sign Up
                        </Button>
                      </li>
                    </Nav>
                  </div>
                </ScrollspyNav>
              </Collapse>
            </Container>
          </nav>
        </div>
      </React.Fragment >
    );
  }
}

export default NavbarPage;
