import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      comments: ''
    };
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  render() {
    return (
      <React.Fragment>
        <section className="section " id="contact">
          <Container>
            {/* section title */}
            <SectionTitle
              title="GET IN TOUCH"
              desc="Have a question or need help with our beta testing program? Contact us now and we'll be happy to assist you."
            />

            <Row>
              <Col lg={2}></Col>
              <Col lg={8}>
                <div className="custom-form mt-4 pt-4">
                  <form name="myForm" onSubmit={(e) => {
                    e.preventDefault();
                    const data = {
                      name: this.state.name,
                      email: this.state.email,
                      comments: this.state.comments
                    };
                    console.log('data', data);
                    fetch('https://api.betatestmyproduct.com/messages/send-landing-page-message', {
                      // fetch('http://localhost:5000/messages/send-landing-page-message', {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify(data)
                    })
                      .then(response => response.json())
                      .then(data => {
                        if (data.success) {
                          window.location.reload();
                        }
                        console.log(data);
                      });

                  }}>
                    <p id="error-msg"></p>
                    <div id="simple-msg"></div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group mt-2">
                          <input name="name" id="name" type="text" class="form-control"
                            onChange={(event) => this.handleUserInput(event)}
                            value={this.state.name}
                            placeholder="Your name*" />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group mt-2">
                          <input name="email" id="email" type="email" class="form-control"
                            onChange={(event) => this.handleUserInput(event)}
                            value={this.state.email}
                            placeholder="Your email*" />
                        </div>
                      </div>
                    </div>
                    {/* <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group mt-2">
                          <input type="text" class="form-control" id="subject" name="subject"
                            placeholder="Your Subject.." />
                        </div>
                      </div>
                    </div> */}
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group mt-2">
                          <textarea name="comments" id="comments" rows="4" class="form-control"
                            value={this.state.comments}
                            onChange={(event) => this.handleUserInput(event)}
                            placeholder="Your message..."></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 text-end">
                        <input type="submit" id="submit" name="send" class="submitBnt btn btn-primary"
                          value="Send Message" />
                        {/* <button type="submit" className="submitBnt btn btn-primary">Send Message</button> */}
                      </div>
                    </div>
                  </form>
                </div>
              </Col>
              <Col lg={2}></Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
