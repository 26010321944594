import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Importing Modal
import ModalSection from "../../components/common/ModalSection";

class Section extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.closeModal.bind(this)
    this.callModal.bind(this);
  }

  callModal = () => {
    this.setState({ isOpen: true })
  };

  closeModal = () => {
    this.setState({ isOpen: false })
  };

  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-home home-half"
          id="home"
          data-image-src="images/bg-home.jpg"
        >
          <div className="bg-overlay"></div>
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row>
                  <Col
                    lg={{ size: 8, offset: 2 }}
                    className="col-lg-8 offset-lg-2 text-white text-center"
                  >
                    <h1 className="home-title">
                      Beta Testing Made Simple
                    </h1>
                    <p className="pt-3 home-desc">
                      {/* Streamlined beta testing of tech products */}
                      Transforming feedback into action, effortlessly.
                    </p>
                    {/* <p className="play-shadow mt-4">
                       <Link
                        to="#"
                        onClick={this.callModal}
                        className="play-btn video-play-icon"
                      >
                        <i className="mdi mdi-play text-center"></i>
                      </Link> 
                  </p> */}
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <div className="wave-effect wave-anim">
            <div className="waves-shape shape-one">
              <div
                className="wave wave-one"
                style={{
                  backgroundImage: `url(assets/images/wave-shape/wave1.png)`,
                }}
              ></div>
            </div>
            <div className="waves-shape shape-two">
              <div
                className="wave wave-two"
                style={{
                  backgroundImage: `url(assets/images/wave-shape/wave2.png)`,
                }}
              ></div>
            </div>
            <div className="waves-shape shape-three">
              <div
                className="wave wave-three"
                style={{
                  backgroundImage: `url(assets/images/wave-shape/wave3.png)`,
                }}
              ></div>
            </div>
          </div>

          {/* Render ModalSection Component for Modal */}
          {/* <ModalSection channel="vimeo" videoId="99025203" closeModal={this.closeModal} isOpen={this.state.isOpen} /> */}
        </section>
      </React.Fragment >
    );
  }
}

export default Section;
