import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

class WebsiteDesc extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section section-lg bg-web-desc">
          <div className="bg-overlay"></div>
          <Container>
            <Row>
              <Col lg={12} className="text-center">
                <h2 className="text-white">We are in Beta!</h2>
                <p className="pt-3 home-desc">
                  {/* Funny how the website about beta testing is in beta itself. While it's being built the beta tests will be free to host! But if we break a few things along the way, promise not to be too mad. */}
                  Isn't it ironic that our beta testing platform is itself in beta? As we perfect our system, enjoy free hosting for your beta tests. And remember, if we hit a few bumps on this journey, your patience is much appreciated!
                </p>
                <a
                  href="https://app.betatestmyproduct.com/user/register"
                  className="btn btn-white mt-4 waves-effect waves-light mb-5"
                >
                  Sign Up
                </a>
              </Col>
            </Row>
          </Container>
          <div className="bg-pattern-effect">
            <img src="assets/images/bg-pattern.png" alt="dorsin" />
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default WebsiteDesc;
